@tailwind base;
@tailwind components;
@tailwind utilities;

.swiper-button-prev,
.swiper-button-next {
    position: absolute;
    top: 50%;
    width: calc(var(--swiper-navigation-size) / 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.swiper-slide.slide-is-active {
    height: 24rem;
}

.swiper-slide-active.slide-is-active {
    height: 30rem;
    width: 18rem !important;
}

.swiper-slide-active.slide-is-active > img {
    height: 13rem;
}

.swiper-slide-active.slide-is-active > p {
    height: 20rem;
    text-align: center;
}

.react-calendar {
    width: 100%;
    padding: 0.5rem;
    border: 0.5px solid gainsboro;
    border-radius: 0.5rem;
 
}

.react-calendar__navigation__label__labelText{
color: #1C4CBB
}
.react-calendar__navigation__label__labelText--from{
    text-transform: uppercase;
    color:#1C4CBB;
    font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
    color:#0096FC;
}


.accordion-content {
    transition: max-height 0.3s ease-out, padding 0.3s ease;
}

/* .react-calendar button {}  */

.react-calendar__month-view__weekdays {
    display: flex;
}

.react-calendar__tile {
    display: flex;
    width: 50%;
    justify-content: center;
    flex-direction: row;
}

.react-calendar__tile:disabled {
    text-decoration: line-through;
    background-color: rgba(220, 220, 220, 0.422);
    color: rgb(90, 81, 81);
}

react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background: rgba(0, 123, 255, 0.697);
}

.react-calendar__tile--now {
    background: salmon;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: gainsboro;
}

.react-calendar__tile--now:enabled:focus {
    background: rgba(0, 123, 255, 0.697);
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: rgba(0, 123, 255, 0.697);
}

.react-calendar__tile--active {
    background: rgba(0, 123, 255, 0.697);
    color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: rgba(0, 123, 255, 0.697);
}

.hasMinDisponibildiad {
    background-color: orange;
}

/* VERIFY TOKEN */
.lds-circle {
    transform: translateZ(1px);
}

.lds-circle > img {
    animation: lds-circle 15s infinite;
}

@keyframes lds-circle {
    0% {
        transform: rotateY(3600deg);
        animation-timing-function: cubic-bezier(1, 1, 1, 1);
    }
}
/* VERIFY TOKEN */

/* SCROLLBAR EN TODA LA APP PREDETERMINADA */

/* FOR FIREFOX */
* {
    scrollbar-width: thin;
    scrollbar-color: #3c96ff #c6ccd1;
}
/* FOR FIREFOX */

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    border-radius: 7px;
    background: #c6ccd1;
}

::-webkit-scrollbar-thumb {
    background: #3c96ff;
    border-radius: 4px;
    border: 1px solid #fff;
}

::-webkit-scrollbar-thumb:hover {
    background: #3e7ecc;
}

/* SCROLLBAR EN TODA LA APP PREDETERMINADA */
