@keyframes scale-up-down {
    0%,
    100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
}

.animated-button {
    animation: scale-up-down 1.5s infinite alternate ease-in-out;
}
